body, html {
    margin: 0;
    padding: 0;
}


@media (min-width: 1024px) {

    .form_input {
        width: 25em !important;
    }

}

@media (min-width: 992px) {
    .custom-vh-100 {
        height: 100vh;
        margin-top: 0;
    }
}