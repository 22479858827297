.browse-blogs-container {
    padding: 20px;
}

.blog-list {
    display: flex;
    flex-direction: column;
}

.blog-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
}

.blog-card h3 {
    margin: 0;
}

.blog-card p {
    margin: 5px 0;
}
